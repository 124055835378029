<template>
    <div>
        <div style="margin-bottom: 5px;">
            <el-input v-model="name" placeholder="请输入物品名" suffix-icon="el-icon-search" style="width: 200px;"
                      @keyup.enter.native="loadPost"></el-input>
            <el-select v-model="storage" placeholder="请选择仓库" style="margin-left: 5px;">
                <el-option
                        v-for="item in storageData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>
            <el-select v-model="goodstype" placeholder="请选择分类" style="margin-left: 5px;">
                <el-option
                        v-for="item in goodstypeData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                </el-option>
            </el-select>

            <el-button type="primary" style="margin-left: 5px;" @click="loadPost">查询</el-button>
            <el-button type="success" @click="resetParam">重置</el-button>


        </div>
        <el-table :data="tableData"
                  :header-cell-style="{ background: '#f2f5fc', color: '#555555' }"
                  border
        >
            <el-table-column prop="id" label="ID" width="60">
            </el-table-column>
            <el-table-column prop="goodsname" label="物品名" width="150">
            </el-table-column>
            <el-table-column prop="storagename" label="仓库" width="150">
            </el-table-column>
            <el-table-column prop="goodstypename" label="分类" width="150">
            </el-table-column>
            <el-table-column prop="adminname" label="操作人" width="150">
            </el-table-column>
            <el-table-column prop="username" label="申请人" width="150">
            </el-table-column>
            <el-table-column prop="count" label="数量" width="150">
            </el-table-column>
            <el-table-column prop="createtime" label="操作时间" width="180">
            </el-table-column>
            <el-table-column prop="remark" label="备注" width="180">
            </el-table-column>
        </el-table>
        <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="pageNum"
                :page-sizes="[5, 10, 20,30]"
                :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="total">
        </el-pagination>
    </div>
</template>

<script>
    export default {
        name: "RecordManage",
        data() {
            // 初始化组件数据，包含用户信息、存储相关数据、表格数据等
            return {
                // 从sessionStorage中获取当前用户信息并解析为JSON对象
                user: JSON.parse(sessionStorage.getItem('CurUser')),
                // 初始化存储数据的数组
                storageData: [],
                // 初始化商品类型数据的数组
                goodstypeData: [],
                // 初始化表格数据的数组
                tableData: [],
                // 每页显示的记录数
                pageSize: 10,
                // 当前页码
                pageNum: 1,
                // 总记录数
                total: 0,
                // 初始化搜索名称字段
                name: '',
                // 初始化搜索存储字段
                storage: '',
                // 初始化搜索商品类型字段
                goodstype: '',
                // 控制对话框可见性的属性
                centerDialogVisible: false,
                // 初始化表单数据对象
                form: {
                    id: '',
                    name: '',
                    storage: '',
                    goodstype: '',
                    count: '',
                    remark: ''
                },
            }
        },
        methods:{
/**
 * 根据存储ID格式化存储名称
 * @param {Object} row 行数据，包含存储ID
 * @returns {String|undefined} 返回对应的存储名称，如果找不到则返回undefined
 */
formatStorage(row) {
    // 在存储数据数组中查找与当前行存储ID匹配的项
    let temp = this.storageData.find(item => {
        return item.id == row.storage
    });

    // 如果找到了匹配的项，则返回存储名称，否则返回undefined
    return temp && temp.name;
},
/**
 * 根据商品类型ID格式化商品类型名称
 * @param {Object} row - 行数据，包含商品类型ID
 * @returns {String} - 返回格式化后的商品类型名称，如果找不到对应的名称则返回空字符串
 */
formatGoodstype(row) {
    // 在goodstypeData数组中查找与商品类型ID匹配的项
    let temp = this.goodstypeData.find(item => {
        return item.id == row.goodstype
    })

    // 如果找到了匹配的项，则返回商品类型名称，否则返回空字符串
    return temp && temp.name
},
            /**
             * 重置表单字段的值。
             * 
             * 此方法用于将表单的所有字段值重置为初始状态，通常在用户取消操作或完成某项任务后调用。
             * 它通过访问表单的 `$refs` 对象，并调用其 `resetFields` 方法来实现。
             * 
             * @see {@link https://element.eleme.io/#/zh-CN/component/form#form-instance}
             */
            resetForm() {
                this.$refs.form.resetFields();
            },
            /**
             * 处理分页大小的变化
             * 
             * 当用户选择每页显示的不同项数时，此函数被调用。
             * 它的目的是更新当前的分页设置，并重新加载数据以反映新的分页大小。
             * 
             * @param {number} val 用户选择的每页项数
             */
            handleSizeChange(val) {
                // 输出当前选择的每页项数到控制台
                console.log(`每页 ${val} 条`);
                
                // 重置页码为第一页，以开始新的分页查询
                this.pageNum = 1;
                
                // 更新每页的项数为用户选择的值
                this.pageSize = val;
                
                // 调用加载帖子的函数，以应用新的分页设置
                this.loadPost();
            },
            /**
             * 处理分页当前页码的变化
             * 
             * 当用户交互式地改变分页控件的当前页码时，此函数被调用。
             * 它的主要作用是更新当前页码的状态，并触发加载对应页码的数据。
             * 
             * @param {number} val - 用户选择的新的当前页码值。
             */
            handleCurrentChange(val) {
                console.log(`当前页: ${val}`); // 日志输出当前选择的页码，便于调试和查看页面状态
                this.pageNum = val; // 更新当前页码的状态，用于后续的数据加载
                this.loadPost(); // 触发加载对应页码的帖子数据的操作
            },
            /**
             * 重置参数设置
             * 
             * 该方法用于将当前的参数设置重置为默认状态，即清空名称、存储和商品类型等字段的值。
             * 这样可以在进行新的参数配置之前，确保所有选项都处于未设置状态。
             * 
             * @returns {void} 该方法没有返回值
             */
            resetParam(){
                this.name=''
                this.storage=''
                this.goodstype=''
            },
            /**
             * 加载存储信息。
             * 通过axios发送GET请求，获取存储列表数据。
             * 请求成功后，根据返回码决定如何处理数据。
             * 如果返回码为200，将数据存储在storageData中。
             * 如果返回码不为200，弹出提示框显示获取数据失败。
             */
            loadStorage(){
                this.$axios.get(this.$httpUrl+'/storage/list').then(res=>res.data).then(res=>{
                    console.log(res)
                    if(res.code==200){
                        this.storageData=res.data
                    }else{
                        alert('获取数据失败')
                    }

                })
            },
            /**
             * 加载商品类型数据
             * 该方法通过发送HTTP GET请求到指定URL，获取商品类型列表数据
             * 如果请求成功并返回码为200，则将获取的数据存储到goodstypeData属性中
             * 如果请求失败或返回码非200，则通过弹窗提示获取数据失败
             */
            loadGoodstype(){
                // 发送GET请求获取商品类型列表
                this.$axios.get(this.$httpUrl+'/goodstype/list').then(res=>res.data).then(res=>{
                    console.log(res)
                    // 判断响应码是否为200，以确定请求是否成功
                    if(res.code==200){
                        // 如果请求成功，将返回的数据赋值给goodstypeData
                        this.goodstypeData=res.data
                    }else{
                        // 如果请求失败或返回码非200，弹窗提示获取数据失败
                        alert('获取数据失败')
                    }

                })
            },
            /**
             * 加载文章列表数据。
             * 通过axios发送POST请求，获取分页后的文章列表数据。
             * 请求的URL由$httpUrl基础URL和'/record/listPage'路径组成。
             * 请求参数包括分页信息（pageSize, pageNum）和查询条件（name, goodstype, storage, roleId, userId）。
             * 请求成功后，更新tableData为返回的数据列表，更新total为数据总条数。
             * 如果请求失败，显示警报提示获取数据失败。
             */
            loadPost(){
                this.$axios.post(this.$httpUrl+'/record/listPage',{
                    pageSize:this.pageSize,
                    pageNum:this.pageNum,
                    param:{
                        name:this.name,
                        goodstype:this.goodstype+'',
                        storage:this.storage+'',
                        roleId:this.user.roleId+'',
                        userId:this.user.id+''                    
                    }
                }).then(res=>res.data).then(res=>{                    
                    console.log(res)
                    if(res.code==200){
                        this.tableData=res.data
                        this.total=res.total
                    }else{
                        alert('获取数据失败')
                    }

                })
            },
        },
        /**
         * 在组件挂载之前执行的初始化方法。
         * 这里主要负责加载初始数据，包括存储信息、商品类型和帖子数据，
         * 以确保组件渲染时具有必要的数据。
         */
        beforeMount() {
            // 加载本地存储中的数据，用于初始化组件状态
            this.loadStorage()
            // 加载商品类型数据，用于展示或筛选商品
            this.loadGoodstype()
            // 加载帖子数据，用于展示或交互
            this.loadPost()
        }
    }
</script>

<style scoped>

</style>